<template>
  <card title="缺陷明细">
    <div class="defect-list">
      <no-data v-if="!loading && defectList && defectList.length === 0"></no-data>
      <div
        v-for="(item, index) in defectList"
        :key="index"
        class="list-item space-between vertical-center"
        @click="handleItemClick(item, index)"
      >
        <div class="info">
          <div class="name" style="color: #4B4E51; font-size: 14px; font-weight: bold">
            {{ index + 1 }}. 【
            <span>CRI {{ item.cri }}</span>
            <span>MAJ {{ item.maj }}</span>
            <span style="margin-right: 0">MIN {{ item.min }}</span>
            】
            <span style="margin-left: 8px">{{ item.description }}</span>
          </div>
          <div style="padding-left: 16px">
            <div style="margin-top: 16px; color: #797C80">缺陷类别：{{ item.defectTypeName }}/{{ item.defectName }}</div>
            <div style="margin-top: 16px; color: #797C80">关联检查点：{{ item.checkPointName }}</div>
          </div>
          <div v-if="item.pictures" class="picture-wrap">
            <image-uploader
              v-model="item.picturesList"
              :deletable="false"
              :show-upload="false"
            ></image-uploader>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import CustomPopup from '@/components/Common/CustomPopup.vue'
import DefectDetailPopup from '@/components/V2/Defect/DefectDetailPopup.vue';
import NewDefectPopup from '@/components/V2/Defect/NewDefectPopup.vue';
import NoData from '@/components/Common/NoData';
import {deleteAction, getAction} from '@/api/manage';
import {mapActions, mapGetters} from 'vuex';
import {cloneDeep} from "lodash";
import {getDocumentTitle} from "@/utils/taskData";
import Card from "@/components/Common/Card.vue";
import imageUploader from "@/components/Common/ImageUploader.vue";
import {imgStrToList} from "@/utils/util";

export default {
  name: 'DefectListCard',
  components: { Card, NoData, NewDefectPopup, DefectDetailPopup, CustomPopup, imageUploader},
  props: {
    defaultCheckItemObj: {
      type: Object,
      default() {
        return {}
      },
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      defectList: [],
      selectIndex: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  watch: {
    taskInfo() {
      this.getDefectList()
    }
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    handleItemClick(item, index) {
      this.selectIndex = index
      this.$refs.detail.disabled = this.readonly
      this.$refs.detail.form = item
    },
    handleAdd() {
      this.selectIndex = null
      this.$refs.form.init()
    },
    handleEdit() {
      this.$refs.form.init(this.defectList[this.selectIndex])
    },
    async handleFormOk() {
      await this.getTaskInfo()
      this.getDefectList()
    },
    getSubmitData(){
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.inspectionList.defectList = this.defectList
      return taskInfo
    },
    handleDel() {
      this.Dialog.confirm({
        title: '',
        message: '确认删除该缺陷？',
      }).then(async () => {
        this.defectList.splice(this.selectIndex, 1)
        const taskInfo = this.getSubmitData()
        const res = await this.saveTaskInfo(taskInfo)
        if (res.success) {
          await this.getTaskInfo()
        }
        this.getDefectList()
      }).catch(async () => {
        this.getDefectList()
      });
    },
    async getDefectList() {
      const orderList = this.taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      const articleNoList = order?.articleNoList || []
      const article = articleNoList.find(item => item.id === this.articleId)
      const defectList = cloneDeep(article?.inspectionList?.defectList || [])
      this.defectList = defectList.map(item => {
        item.picturesList = imgStrToList(item.pictures)
        return item
      })
    }
  },
}
</script>

<style scoped lang="less">
.defect-list {

  .list-item {
    padding: 6px 8px;
    border-bottom: 1px solid #DFE4EB;

    &:last-child {
      border-bottom: none;
    }

    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .info {
      font-size: 14px;

      span {
        color: rgba(#000, 0.4);
        margin-right: 8px;
      }

      .name {
        color: rgba(#000, 0.9);
        span {
          color: rgba(#000, 0.9);
        }
      }
    }

    .picture-wrap {
      margin-top: 16px;
      padding-left: 16px;
      img {
        width: 80px;
        height: 60px;
        object-fit: cover;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }

  .new-btn {
    margin-top: 25px;

    &.disabled {
      display: none;
    }
  }
}
</style>
