<template>
  <card title="缺陷统计汇总">
    <div class="table">
      <div class="th">
        <div class="td td-0">缺陷类别</div>
        <div class="td td-1">缺陷名称</div>
        <div class="td td-2">CRI</div>
        <div class="td td-3">MAJ</div>
        <div class="td td-4">MIN</div>
      </div>
      <div class="tbody">
        <div
          v-for="(item, index) in tableData"
          :key="index"
          :class="{
              'tr': true,
              'total-tr': item.littleTotal || item.allTotal,
            }"
        >
          <div :class="['td td-0', item.isMerge ? 'two-td' : '']">{{ item.defectTypeName }}</div>
          <div v-if="!item.isMerge" class="td td-1">{{ item.defectName }}</div>
          <div :class="['td td-2', item.allTotal && item.cri > referCri ? 'red' : '']">
            {{ item.cri }}
          </div>
          <div :class="['td td-3', item.allTotal && item.maj > referMaj ? 'red' : '']">
            {{ item.maj }}
          </div>
          <div :class="['td td-4', item.allTotal && item.min > referMin ? 'red' : '']">
            {{ item.min }}
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import {mapGetters} from "vuex";
import {cloneDeep} from "lodash";

export default {
  name: "DefectStaticCardNew",
  components: {
    Card,
  },
  props: {
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    },
    articleInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tableData: [],
      defectList: [],
      referCri: 0,
      referMaj: 0,
      referMin: 0,
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  watch: {
    articleInfo: function () {
      this.getDefectList()
    }
  },
  methods: {
    async getDefectList() {
      this.tableData = []
      this.defectList = cloneDeep(this.articleInfo?.inspectionList?.defectList || [])
      const compare = function (objA, objB) {
        return objA.defectTypeCode.localeCompare(objB.defectTypeCode)
      }
      this.defectList = this.defectList.sort(compare)
      console.log(this.defectList)
      let currentDefectTypeCode = null
      let currentDefectCode = null
      let currentTotalIndex = 0

      this.defectList.forEach(item => {
        if (currentDefectTypeCode !== item.defectTypeCode) {
          currentDefectTypeCode = item.defectTypeCode
          this.tableData.push({
            defectTypeName: item.defectTypeName,
            defectName: '',
            cri: 0,
            maj: 0,
            min: 0,
            littleTotal: true,
          })
          currentTotalIndex = this.tableData.length - 1
        }
        if (currentDefectCode !== item.defectCode) {
          currentDefectCode = item.defectCode
          this.tableData.push({
            defectTypeName: '',
            defectName: item.defectName,
            cri: item.cri,
            maj: item.maj,
            min: item.min,
          })
        } else if (this.tableData.length) {
          this.tableData[this.tableData.length - 1].cri += item.cri
          this.tableData[this.tableData.length - 1].maj += item.maj
          this.tableData[this.tableData.length - 1].min += item.min
        }

        this.tableData[currentTotalIndex].cri += item.cri
        this.tableData[currentTotalIndex].maj += item.maj
        this.tableData[currentTotalIndex].min += item.min
      })
      const aqlCri = this.tableData.reduce((prev, cur) => {
        return prev + cur.cri
      }, 0)
      const aqlMaj = this.tableData.reduce((prev, cur) => {
        return prev + cur.maj
      }, 0)
      const aqlMin = this.tableData.reduce((prev, cur) => {
        return prev + cur.min
      }, 0)
      this.tableData.push({
        defectTypeName: '总计',
        cri: aqlCri / 2,
        maj: aqlMaj / 2,
        min: aqlMin / 2,
        allTotal: true,
        isMerge: true,
      })
      this.referCri = this.getReferenceData(this.articleInfo.aqlCri, this.articleInfo.suggestedQuantity)
      this.referMaj = this.getReferenceData(this.articleInfo.aqlMaj, this.articleInfo.suggestedQuantity)
      this.referMin = this.getReferenceData(this.articleInfo.aqlMin, this.articleInfo.suggestedQuantity)
      this.tableData.push({
        defectTypeName: '参考值',
        cri: this.referCri,
        maj: this.referMaj,
        min: this.referMin,
        isMerge: true,
      })
    },
    // 根据aql值和抽检数量获取对应的参考值
    getReferenceData(aql, suggestedQuantity) {
      // 质量标准表及其对应值
      const data = {
        "0.065": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 0,
          200: 0,
          315: 0,
          500: 1,
          800: 1,
          1250: 2,
          2000: 3,
        },
        "0.10": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 0,
          200: 0,
          315: 1,
          500: 1,
          800: 2,
          1250: 3,
          2000: 5,
        },
        "0.15": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 0,
          200: 1,
          315: 1,
          500: 2,
          800: 3,
          1250: 5,
          2000: 7,
        },
        "0.25": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 1,
          200: 1,
          315: 2,
          500: 3,
          800: 5,
          1250: 7,
          2000: 10,
        },
        "0.40": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 1,
          125: 1,
          200: 2,
          315: 3,
          500: 5,
          800: 7,
          1250: 10,
          2000: 14,
        },
        "0.65": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 1,
          80: 1,
          125: 2,
          200: 3,
          315: 5,
          500: 7,
          800: 10,
          1250: 14,
          2000: 21,
        },
        "1.0": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 1,
          50: 1,
          80: 2,
          125: 3,
          200: 5,
          315: 7,
          500: 10,
          800: 14,
          1250: 21,
          2000: 21,
        },
        "1.5": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 1,
          32: 1,
          50: 2,
          80: 3,
          125: 5,
          200: 7,
          315: 10,
          500: 14,
          800: 21,
          1250: 21,
          2000: 21,
        },
        "2.5": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 1,
          20: 1,
          32: 2,
          50: 3,
          80: 5,
          125: 7,
          200: 10,
          315: 14,
          500: 21,
          800: 21,
          1250: 21,
          2000: 21,
        },
        "4.0": {
          2: 0,
          3: 0,
          5: 0,
          8: 1,
          13: 1,
          20: 2,
          32: 3,
          50: 5,
          80: 7,
          125: 10,
          200: 14,
          315: 21,
          500: 21,
          800: 21,
          1250: 21,
          2000: 21,
        },
        "6.5": {
          2: 0,
          3: 0,
          5: 1,
          8: 1,
          13: 2,
          20: 3,
          32: 5,
          50: 7,
          80: 10,
          125: 14,
          200: 21,
          315: 21,
          500: 21,
          800: 21,
          1250: 21,
          2000: 21,
        },
      };

      if (aql == "Not Allowed") {
        return 0;
      } else {
        let num = Number(suggestedQuantity);
        // 根据aql值和抽检数量在data对象获取对应值
        return data[aql][num] || 0;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.table {
  .th, .tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 40px;

    .td {
      border-top: 1px solid #E6E6E6;
      border-left: 1px solid #E6E6E6;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #797C80;
      overflow: hidden;
      text-overflow: ellipsis;
      //display: -webkit-box;  // 与flex布局冲突，要么一行省略号+竖向居中，要么两行省略号就无法居中
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      padding: 5px 0;
    }

    .td-2, .td-3, .td-4 {
      width: 37px;
      flex-shrink: 0;
      text-align: center;
    }

    .td-4 {
      border-right: 1px solid #E6E6E6;
    }

    .td-0, .td-1 {
      width: calc((100% - 37px * 3) / 2);
    }

    .two-td {
      width: calc(100% - 37px * 3);
      font-weight: 700;
      font-size: 12px;
      color: #4B4E51;
    }

    &.th {
      background: #D8E6EF;
      font-weight: 700;
      font-size: 12px;
      color: #4B4E51;
    }

    &.total-tr {
      background: #EFF4F9;

      .td {
        border-left: none;

        &:first-child {
          border-left: 1px solid #E6E6E6;
        }
      }
    }

    &.tr:last-child {
      border-bottom: 1px solid #E6E6E6;

      .td {
        border-left: none;

        &:first-child {
          border-left: 1px solid #E6E6E6;
        }
      }
    }

    .td {
      &.red {
        font-weight: 700;
        color: #F94837;
      }
    }
  }
}
</style>