<template>
  <card title="任务基本信息">
    <!--    <div class="info-item">-->
    <!--      <div class="label">验货任务</div>-->
    <!--      <div class="value">{{ taskName }}</div>-->
    <!--    </div>-->
    <div class="info-item">
      <div class="label">订单号</div>
      <div class="value">{{ orderNum }}</div>
    </div>
    <div class="info-item">
      <div class="label">货号</div>
      <div class="value">{{ articleInfo.articleNo }}</div>
    </div>
    <div class="info-item">
      <div class="label">订单数量</div>
      <div class="value">{{ articleInfo.applicationQuantity }}</div>
    </div>
    <div class="info-item">
      <div class="label">完成数量</div>
      <div v-if="articleInfo.skipReason" class="value">未点数：{{ articleInfo.skipReason }}</div>
      <div v-else class="value">{{ articleInfo.completedQuantity || '/' }}（{{completePercent}}）</div>
    </div>
    <div class="info-item">
      <div class="label">抽检水平</div>
      <div class="value">{{ articleInfo.samplingLevel || '/' }}</div>
    </div>
    <div class="info-item">
      <div class="label">抽检数量</div>
      <div class="value">{{ articleInfo.samplingQuantity || '无' }}</div>
    </div>
    <div class="info-item">
      <div class="label">AQL</div>
      <div class="value">CRI {{ articleInfo.aqlCri }}, MAJ {{ articleInfo.aqlMaj }}, MIN
        {{ articleInfo.aqlMin }}
      </div>
    </div>
    <div class="info-item">
      <div class="label">供应商</div>
      <div class="value">{{ taskInfo.supplierName }}</div>
    </div>
    <div class="info-item">
      <div class="label">验货工厂</div>
      <div class="value highlight" @click="handleAddr">
        {{ taskInfo.factoryAddr }}
        <img class="icon-location" src="~@/assets/img/icon_location.png" alt="">
      </div>
    </div>
    <!--    <div class="info-item">-->
    <!--      <div class="label">货号描述</div>-->
    <!--      <div class="value">{{ articleInfo.articleNoDesc }}</div>-->
    <!--    </div>-->
    <!--    <div class="info-item">-->
    <!--      <div class="label">验货数量</div>-->
    <!--      <div class="value">{{ articleInfo.completedQuantity }}</div>-->
    <!--    </div>-->
    <!--    <div class="info-item">-->
    <!--      <div class="label">验货类型</div>-->
    <!--      <div class="value">{{ taskInfo.inspectionType_dictText }}</div>-->
    <!--    </div>-->
<!--    <div class="info-item">-->
<!--      <div class="label">验货结论</div>-->
<!--      <div class="value">-->
<!--        <span :style="{color: ARTICLE_CONCLUSION_DISAGREE === inspectorConclusion.conclusion ? '#F94837' : '#4B4E51'}">{{-->
<!--            ARTICLE_CONCLUSION_DICT[inspectorConclusion.conclusion]-->
<!--          }}</span>-->
<!--        <span>（ {{ inspectorConclusion.user }} {{ inspectorConclusion.submitTime }}）</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="info-item">-->
<!--      <div class="label">供应商意见</div>-->
<!--      <div v-if="articleInfo.supplierConfirm && supplierConclusion" class="value">-->
<!--        <span :style="{color: SUPPLIER_CONCLUSION_DISAGREE === supplierConclusion.conclusion ? '#F94837' : '#4B4E51'}">{{-->
<!--            SUPPLIER_CONCLUSION_DICT[supplierConclusion.conclusion]-->
<!--          }}</span>-->
<!--        <span>{{ supplierConclusion.user }} {{ supplierConclusion.submitTime }}</span>-->
<!--      </div>-->
<!--      <div v-else class="value">无</div>-->
<!--    </div>-->
    <!--    <div v-if="!isShow" class="more-btn center" @click="isShow = true">-->
    <!--      查看更多-->
    <!--      <img src="@/assets/svg/icon-arrow-right.svg" alt="">-->
    <!--    </div>-->
    <!--    <transition name="fade">-->
    <!--      <div v-if="isShow">-->
    <!--        <div class="info-item">-->
    <!--          <div class="label">验货员</div>-->
    <!--          <div class="value">{{ taskInfo.inspector }}</div>-->
    <!--        </div>-->
    <!--        <div class="info-item">-->
    <!--          <div class="label">开始时间</div>-->
    <!--          <div class="value">{{ articleInfo.acceptTime }}</div>-->
    <!--        </div>-->
    <!--        <div class="info-item">-->
    <!--          <div class="label">完成时间</div>-->
    <!--          <div class="value">{{ articleInfo.finishTime }}</div>-->
    <!--        </div>-->
    <!--        <div class="info-item">-->
    <!--          <div class="label">工厂联系人</div>-->
    <!--          <div class="value">{{ taskInfo.factoryContact }}</div>-->
    <!--        </div>-->
    <!--        <div class="info-item">-->
    <!--          <div class="label">联系人电话</div>-->
    <!--          <div class="value highlight" @click="handleCall(factoryPhoneNumber)">-->
    <!--            <span>{{ factoryPhoneNumber }}</span>-->
    <!--            <img class="icon-phone" src="~@/assets/svg/icon-phone.svg" alt="">-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </transition>-->
  </card>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import { mapGetters } from "vuex";
import { getDocumentTitle } from "@/utils/taskData";
import { cloneDeep } from "lodash";
import {
  ARTICLE_CONCLUSION_DICT,
  ARTICLE_CONCLUSION_DISAGREE,
  SUPPLIER_CONCLUSION_DICT,
  SUPPLIER_CONCLUSION_DISAGREE
} from "@/constants";

export default {
  name: "DetailCard",
  components: {
    Card
  },
  props: {
    articleInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    orderNum: {
      type: String,
      default: ''
    },
    supplierConclusion: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      isShow: true,
      ARTICLE_CONCLUSION_DICT,
      ARTICLE_CONCLUSION_DISAGREE,
      SUPPLIER_CONCLUSION_DICT,
      SUPPLIER_CONCLUSION_DISAGREE
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
    factoryPhoneNumber() {
      if (this.taskInfo.factoryPhone) {
        return this.taskInfo.factoryPhone
      } else return this.taskInfo.factoryTel
    },
    completePercent() {
      const completedQuantity = parseInt(this.articleInfo?.completedQuantity || 0)
      const applicationQuantity = parseInt(this.articleInfo?.applicationQuantity || 0)
      if (applicationQuantity !== 0) {
        const percent = completedQuantity * 100 / applicationQuantity
        return `${parseInt(percent)}%`
      }
      return ''
    },
    inspectorConclusion() {
      let conclusionList = cloneDeep(this.articleInfo?.conclusionList || [])
      return conclusionList.reverse().find(item => item.role === 'INSPECTOR') || {}
    },
    taskName() {
      return getDocumentTitle(this.taskInfo)
    },
    completeTime() {
      let conclusionList = cloneDeep(this.articleInfo.conclusionList || [])
      conclusionList = conclusionList.filter(item => item.role === 'INSPECTOR')
      if (conclusionList.length) {
        return conclusionList[conclusionList.length - 1]?.submitTime
      }
      return ''
    },
  },
  methods: {
    handleCall(phone) {
      window.location.href = `tel:${ phone }`
    },
    handleAddr() {
      if (this.taskInfo.factoryLatitude && this.taskInfo.factoryLongitude) {
        window.nativeApp.mapNavigation(`${ this.taskInfo.factoryLatitude }, ${ this.taskInfo.factoryLongitude }`, this.taskInfo.factoryAddr);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.info-item {
  line-height: 20px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 0;
  }

  .label {
    width: 80px;
    font-size: 12px;
    color: #AAB3BA;
  }

  .value {
    width: calc(100% - 80px);
    font-size: 14px;
    word-break: break-all;
    color: #4B4E51;
  }

  .icon-location {
    width: 8px;
    height: 10px;
  }

  .icon-phone {
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }

  .highlight {
    color: #055C9D !important;
  }
}

.more-btn {
  margin-top: 16px;
  font-size: 12px;
  color: #AAB3BA;
  text-align: left;
  line-height: 12px;
  img {
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
    margin-left: 5px;
  }
}
.fade-enter-active {
  transition: all 0.5s ease;
}
.fade-enter {
  transform: translateY(-20px);
  opacity: 0;
}

</style>