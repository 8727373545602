<template>
  <div class="result-wrapper">
    <div class="content">
      <card class="title-card" :show-divider="false">
        <div style="font-size: 16px; margin-bottom: 10px">{{ articleInfo ? articleInfo.articleNo : '' }}</div>
        <div style="color: #797C80; font-size: 12px">{{ articleInfo ? articleInfo.articleNoDesc : '' }}</div>
      </card>
      <div :class="['status-box', `status-box-${this.lastConclusion || ''}`]">
        验货结论：{{ ARTICLE_CONCLUSION_DICT[this.lastConclusion] }}
      </div>
      <!--      <div v-if="isShowSupplierConfirm" class="status-box">-->
      <div :class="['status-box', supplierConclusion ? `status-box-${supplierConfirm}` : '']">
        供应商意见：
        <span v-if="supplierConclusion">
          {{ SUPPLIER_CONCLUSION_DICT[supplierConfirm] }} （{{ supplierConclusion.submitTime }} {{ supplierConclusion.user }}）
        </span>
        <span v-else>无</span>
      </div>
      <!--      <div v-if="lastConclusion === ARTICLE_CONCLUSION_AGREE" class="status-box">-->
      <!--        供应商意见：-->
      <!--        <span :style="{'color': '#35BF89'}">同意</span>-->
      <!--      </div>-->
      <detail-card
        class="tag-card-box"
        :article-info="articleInfo"
        :order-num="orderNum"
        :supplier-conclusion="supplierConclusion"
      ></detail-card>
      <Card class="sample-card tag-card-box">
        <div class="space-between sample-title-box" slot="title">
          <div>点数抽样</div>
        </div>
        <image-uploader
          v-if="storehousePictures && storehousePictures.length"
          v-model="storehousePictures"
          :show-upload="false"
          :deletable="false"
        />
        <div v-else class="info-item">
          <div class="label">点数抽样照片</div>
          <div class="value">无</div>
        </div>
      </Card>
      <inspection-pictures-card
        v-model="inspectionPictures"
        :show-upload="false"
        :deletable="false"
        class="tag-card-box"
        @input="onInspectionPicturesChange"
      />
      <defect-static-card-new
        class="tag-card-box"
        :order-id="orderId"
        :article-id="articleId"
        :article-info="articleInfo"
      ></defect-static-card-new>
      <defect-list-card
        class="tag-card-box"
        :order-id="orderId"
        :article-id="articleId"
      />
      <remark-list-card
        class="tag-card-box"
        :order-id="orderId"
        :article-id="articleId"
      />
      <Card class="check-item-card-box tag-card-box">
        <div class="space-between" slot="title">
          <div>检验清单</div>
        </div>
      </Card>
      <check-item-card2
        v-for="(item, index) in checkPointList"
        :key="index"
        :check-point="item"
        :remark-list="remarkList"
        :defect-list="defectList"
      />
<!--      <check-item-card-->
<!--        ref="checkItemCard"-->
<!--        :order-id="orderId"-->
<!--        :article-id="articleId"-->
<!--        :article-info="articleInfo"-->
<!--        :readonly="readonly"-->
<!--        @handleCheckPopupOk="handleCheckPopupOk"-->
<!--        @onCustomCheckItemDel="handleCheckPopupOk"-->
<!--      ></check-item-card>-->
    </div>
    <div class="fixed-btn">
      <div v-if="isShowEditBtn" class="btn-box space-between vertical-center">
        <van-button
          type="primary"
          size="large"
          :loading="btnLoading"
          :disabled="btnLoading"
          loading-text="提交中"
          color="#055C9D"
          @click="handleSubmit"
        >
          修改报告
        </van-button>
      </div>
    </div>
    <check-result-dialog
      ref="checkResultDialog"
      @on-ok="onCheckResultOk"
    />
  </div>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import DetailCard from "@/components/V2/Result/DetailCard.vue";
import InspectionPicturesCard from "@/components/Common/InspectionPicturesCard.vue";
import CheckItemCard from "@/components/Common/CheckItemCard.vue";
import DefectStaticCard from "@/components/V2/Result/DefectStaticCard.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getArticleNoRealInfo} from "@/utils/getDataByAxios";
import {getRandomId, imgStrToList} from "@/utils/util";
import {cloneDeep} from "lodash";
import NewCheckPointPopup from "@/components/V2/Check/NewCheckPointPopup.vue";
import CheckResultDialog from "@/components/V2/Check/CheckResultDialog.vue";
import DefectListPopup from "@/components/V2/Defect/DefectListPopup.vue";
import RemarkListPopup from "@/components/V2/Remark/RemarkListPopup.vue";
import {
  ARTICLE_NO_WAITING_CERTAIN,
  ARTICLE_CONCLUSION_DICT,
  SUPPLIER_CONCLUSION_DICT,
  SUPPLIER_CONCLUSION_AGREE,
  taskStatusObj,
  ARTICLE_CONCLUSION_AGREE,
  ARTICLE_NO_COMPLETED
} from "@/constants";
import {isNative} from "@/config";
import moment from "moment";
import DefectListCard from "@/components/V2/Result/DefectListCard.vue";
import RemarkListCard from "@/components/V2/Result/RemarkListCard.vue";
import BarCodeCard from "@/components/V2/Result/CheckItemCard2.vue";
import CheckItemCard2 from "@/components/V2/Result/CheckItemCard2.vue";
import ImageUploader from "@/components/Common/ImageUploader.vue";
import DefectStaticCardNew from "@/components/V2/Result/DefectStaticCardNew.vue";

export default {
  name: "result",
  components: {
    CheckItemCard2,
    BarCodeCard,
    RemarkListCard,
    DefectListCard,
    RemarkListPopup, DefectListPopup, CheckResultDialog, NewCheckPointPopup,
    Card,
    DetailCard,
    InspectionPicturesCard,
    CheckItemCard,
    DefectStaticCard,
    ImageUploader,
    DefectStaticCardNew,
  },
  data() {
    return {
      orderId: '',
      articleId: '',
      articleInfo: {},
      storehousePictures: [],
      inspectionPictures: [],
      isShowDefectListPop: false,
      isShowRemarkListPop: false,
      btnLoading: false,
      checkPointList: [],
      customCheckPointList: [],
      conclusion: '',
      conclusionReason: '',
      supplierConfirm: SUPPLIER_CONCLUSION_AGREE,
      lastConclusion: '',
      conclusionList: [],
      remarkList: [],
      defectList: [],
      ARTICLE_CONCLUSION_DICT,
      ARTICLE_CONCLUSION_AGREE,
      SUPPLIER_CONCLUSION_DICT,
      SUPPLIER_CONCLUSION_AGREE
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
    orderNum() {
      const orderList = this.taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      return order?.orderNum || ''
    },
    readonly() {
      return false
      // const isTaskStatusApplyCancel = this.taskInfo?.status === taskStatusObj.TASK_STATUS_APPLY_CANCEL
      // return !this.isShowSupplierConfirm
      //   || (this.supplierConfirm === SUPPLIER_CONCLUSION_AGREE && this.isShowSupplierConfirm)
      //   || isTaskStatusApplyCancel
    },
    isShowSupplierConfirm() {
      let isSupplierLastSubmit = false
      let conclusionList = this.conclusionList
      if (conclusionList.length) {
        isSupplierLastSubmit = conclusionList[conclusionList.length - 1].role === 'SUPPLIER'
      }
      return this.supplierConfirm && isSupplierLastSubmit
    },
    completePercent() {
      const completedQuantity = parseInt(this.articleInfo?.completedQuantity || 0)
      const applicationQuantity = parseInt(this.articleInfo?.applicationQuantity || 0)
      if (applicationQuantity !== 0) {
        const percent = completedQuantity * 100 / applicationQuantity
        return `（${parseInt(percent)}%）`
      }
      return ''
    },
    isShowEditBtn() {
      let conclusionList = this.conclusionList || []
      if (conclusionList.length) {
        const firstTime = conclusionList[0].submitTime
        const day = moment().diff(moment(firstTime), 'days')
        return day < 2
      }
      return true
    },
    supplierConclusion() {
      let conclusionList = cloneDeep(this.conclusionList || []).reverse()
      // if (this.isShowSupplierConfirm) {
      //   return conclusionList[conclusionList.length - 1]
      // }
      const index = conclusionList.findIndex(item => item.role === 'SUPPLIER')
      if (index === 0) {
        console.log('ok...', this.conclusionList.length, this.conclusionList[0])
        return conclusionList[index]
      }
      return null
    },
  },
  async created() {
    this.SET_TASK_ID(this.$route.query.taskId)
    this.orderId = this.$route.query.orderId
    this.articleId = this.$route.query.articleId.replace(/\?time=(\d+)/g, '')
    await this.getTaskInfo()
    this.getArticleInfo()
    this.getCheckItemList()
    this.getSupplierConclusion()
    if (isNative) {
      window.nativeApp.showMoreBtn(false)
    }
  },
  beforeDestroy() {
    if (isNative) {
      window.nativeApp.showMoreBtn(false)
    }
  },
  methods: {
    ...mapMutations(['SET_TASK_ID']),
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    handleEditSample() {
      this.$router.push({
        path: '/countSample',
        query: {
          ...this.$route.query,
          from: 'result'
        },
      })
    },
    async onInspectionPicturesChange() {
      const taskInfo = await this.getSubmitData()
      const res = await this.saveTaskInfo(taskInfo)
      if (res.success) {
        await this.getTaskInfo()
        this.getArticleInfo()
        this.getCheckItemList()
      }
    },
    async getSubmitData(articleStatus) {
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.inspectionPictures = this.inspectionPictures.map(item => item.url).join(',')
      article.conclusion = this.conclusion
      article.conclusionReason = this.conclusionReason
      article.supplierConfirm = this.conclusion === ARTICLE_CONCLUSION_AGREE ? SUPPLIER_CONCLUSION_AGREE : ''
      article.supplierremarks = ''
      if (this.conclusion) {
        article.finishTime = moment().format('YYYY-MM-DD HH:mm:ss')
        article.conclusionTime = moment().format('YYYY-MM-DD HH:mm:ss')
      }
      let user = 'H5'
      let userId = ''
      if (isNative) {
        try {
          const res = await window.nativeApp.getUserInfo()
          if (res.success) {
            const result = JSON.parse(res.result)
            userId = result.userId
            user = result.user
          }
        } catch (e) {
          console.log('获取用户信息出错：')
          console.error(e)
        }
      }
      if (articleStatus) {
        article.status = articleStatus
      }
      if ([ARTICLE_NO_WAITING_CERTAIN, ARTICLE_NO_COMPLETED].includes(articleStatus)) {
        article.conclusionList.push({
          id: getRandomId(),
          role: "INSPECTOR",
          user,
          userId,
          submitTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          conclusion: this.conclusion
        })
      }
      return taskInfo
    },
    handleSubmit() {
      this.$router.push({
        path: 'check',
        query: {
          ...this.$route.query,
          checkFrom: 'result',
        }
      })
    },
    async handleCheckPopupOk() {
      await this.getTaskInfo()
      this.getArticleInfo()
      this.getCheckItemList()
    },
    async onCheckResultOk(form) {
      this.conclusion = form.result
      this.conclusionReason = form.reason
      const articleStatus = form.result === ARTICLE_CONCLUSION_AGREE ? ARTICLE_NO_COMPLETED : ARTICLE_NO_WAITING_CERTAIN
      const taskInfo = await this.getSubmitData(articleStatus)
      const res = await this.saveTaskInfo(taskInfo)
      this.conclusion = ''
      this.conclusionReason = ''
      if (res.success) {
        this.$router.back()
      }
    },
    async getSupplierConclusion() {
      const res = await getArticleNoRealInfo(this.articleId)
      if (res && res.success) {
        this.supplierConfirm = res.result.supplierConfirm
        this.conclusionList = res.result.conclusionList.reverse() || []
      }
    },
    getArticleInfo() {
      const orderList = this.taskInfo.orderList || []
      const order = orderList.find(item => item.id === this.orderId) || {}
      const articleNoList = order.articleNoList || []
      this.articleInfo = articleNoList.find(item => item.id === this.articleId) || {}
      this.lastConclusion = this.articleInfo.conclusion
      this.supplierConfirm = this.articleInfo.supplierConfirm
      const defectList = cloneDeep(this.articleInfo?.inspectionList?.defectList || [])
      this.defectList = defectList.map(item => {
        item.picturesList = imgStrToList(item.pictures)
        return item
      })
      const remarkList = cloneDeep(this.articleInfo?.inspectionList?.remarkList || [])
      this.remarkList = remarkList.map(item => {
        item.picturesList = imgStrToList(item.pictures)
        return item
      })
      this.inspectionPictures = imgStrToList(this.articleInfo.inspectionPictures)
      this.storehousePictures = imgStrToList(this.articleInfo.storehousePictures)
      this.conclusionList = cloneDeep(this.articleInfo.conclusionList || [])
      document.title = `${this.articleInfo?.articleNo || '--'}的验货报告`
      // 没用了的代码
      // if (this.conclusionList.length) {
      //   this.result = this.conclusionList[conclusionList.length - 1].conclusion
      // }
    },
    getCheckItemList() {
      this.checkPointList = cloneDeep(this.articleInfo.inspectionList.checkPointList)
      // let spliceIndexList = []
      // checkPointList.forEach((item, index) => {
      //   const root = this.checkPointList.find(root => root.inspectionItemId === item.inspectionItemId)
      //   if (root) {
      //     if (!root.children?.length) {
      //       root.children = [item]
      //     } else {
      //       root.children.push(item)
      //       spliceIndexList.push(index)
      //     }
      //   } else {
      //     this.checkPointList.push({
      //         inspectionItemId: item.inspectionItemId,
      //         inspectionItem: item.inspectionItem,
      //         children: [item]
      //       }
      //     )
      //   }
      // })
      // this.checkPointList = this.checkPointList.filter((item, index) => !spliceIndexList.includes(index))

      const customCheckPointList = cloneDeep(this.articleInfo.inspectionList.customCheckPointList)
      this.customCheckPointList = customCheckPointList
      // let newSpliceIndexList = []
      // customCheckPointList.forEach((item, index) => {
      //   const root = this.customCheckPointList.find(root => root.inspectionItemName === item.inspectionItemName)
      //   if (root) {
      //     if (!root.children?.length) {
      //       root.children = [item]
      //     } else {
      //       root.children.push(item)
      //       newSpliceIndexList.push(index)
      //     }
      //   } else {
      //     this.customCheckPointList.push({
      //         inspectionItemName: item.inspectionItemName,
      //         children: [item]
      //       }
      //     )
      //   }
      // })
      // this.customCheckPointList = this.customCheckPointList.filter((item, index) => !newSpliceIndexList.includes(index))

      // this.$refs.checkItemCard.init(this.checkPointList, this.customCheckPointList)
    },
  }
}
</script>

<style lang="less" scoped>
.result-wrapper {
  height: 100vh;
  overflow-y: auto;

  .content {
    background: #F9F9F9;
    padding: 1px 16px 156px;

    &::v-deep .title-card {
      .title {
        display: none;
      }
    }

    &::v-deep .tag-card-box {
      .title {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: -16px;
          top: 17px;
          width: 6px;
          height: 14px;
          background: #055C9D;
        }
      }
    }

    .check-item-card-box {
      ::v-deep {
        .divider, .card-content {
          display: none;
        }
      }
    }

    .sample-card {

      .info-item {
        line-height: 20px;
        margin-bottom: 8px;
        display: flex;

        &:last-of-type {
          margin-bottom: 0;
        }

        .label {
          font-size: 12px;
          color: #AAB3BA;
          flex-shrink: 0;
          margin-right: 10px;
        }

        .value {
          font-size: 14px;
          word-break: break-all;
          color: #4B4E51;
        }
      }

      .sample-title-box {
        width: 100%;

        .edit-btn {
          color: #055C9D;
          font-size: 14px;
        }
      }
    }

    .status-box {
      padding: 5px 16px;
      background: #EFFAF6;
      border: 1px solid #35bf8929;
      border-radius: 4px;
      font-size: 14px;
      color: #35BF89;
      margin-top: 16px;

      &.status-box-2, &.status-box-N {
        background: #FFF4F3;
        border-color: #f9483729;
        color: #F94837;
      }

      &.status-box-3 {
        background: #FFFAEE;
        border-color: #ffbd2e29;
        color: #FFBD2E;
      }

      &.status-box-4 {
        background: #EBF7FF;
        border-color: #055c9d29;
        color: #055C9D;
      }
    }
  }

  .fixed-btn {
    .step-btn {
      width: 100%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn-box {
      margin-top: 16px;
    }
  }
}
</style>