<template>
  <card title="缺陷统计汇总">
    <custom-table :columns="columns" :table-data="tableData"/>
  </card>
</template>

<script>
import CustomTable from "@/components/Common/CustomTable.vue";
import Card from "@/components/Common/Card.vue";
import {cloneDeep} from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "DefectStaticCard",
  components: {Card, CustomTable},
  props: {
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    },
    articleInfo: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data() {
    return {
      // columns: ['缺陷类别', '缺陷名称', 'CRI', 'MAJ', 'MIN'],
      columns: ['缺陷类别', 'CRI', 'MAJ', 'MIN'],
      tableData: [],
      defectList: []
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),

  },
  watch: {
    articleInfo: function () {
      this.getDefectList()
    }
  },
  methods: {
    // 根据aql值和抽检数量获取对应的参考值
    getReferenceData(aql, suggestedQuantity) {
      // 质量标准表及其对应值
      const data = {
        "0.065": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 0,
          200: 0,
          315: 0,
          500: 1,
          800: 1,
          1250: 2,
          2000: 3,
        },
        "0.10": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 0,
          200: 0,
          315: 1,
          500: 1,
          800: 2,
          1250: 3,
          2000: 5,
        },
        "0.15": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 0,
          200: 1,
          315: 1,
          500: 2,
          800: 3,
          1250: 5,
          2000: 7,
        },
        "0.25": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 0,
          125: 1,
          200: 1,
          315: 2,
          500: 3,
          800: 5,
          1250: 7,
          2000: 10,
        },
        "0.40": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 0,
          80: 1,
          125: 1,
          200: 2,
          315: 3,
          500: 5,
          800: 7,
          1250: 10,
          2000: 14,
        },
        "0.65": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 0,
          50: 1,
          80: 1,
          125: 2,
          200: 3,
          315: 5,
          500: 7,
          800: 10,
          1250: 14,
          2000: 21,
        },
        "1.0": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 0,
          32: 1,
          50: 1,
          80: 2,
          125: 3,
          200: 5,
          315: 7,
          500: 10,
          800: 14,
          1250: 21,
          2000: 21,
        },
        "1.5": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 0,
          20: 1,
          32: 1,
          50: 2,
          80: 3,
          125: 5,
          200: 7,
          315: 10,
          500: 14,
          800: 21,
          1250: 21,
          2000: 21,
        },
        "2.5": {
          2: 0,
          3: 0,
          5: 0,
          8: 0,
          13: 1,
          20: 1,
          32: 2,
          50: 3,
          80: 5,
          125: 7,
          200: 10,
          315: 14,
          500: 21,
          800: 21,
          1250: 21,
          2000: 21,
        },
        "4.0": {
          2: 0,
          3: 0,
          5: 0,
          8: 1,
          13: 1,
          20: 2,
          32: 3,
          50: 5,
          80: 7,
          125: 10,
          200: 14,
          315: 21,
          500: 21,
          800: 21,
          1250: 21,
          2000: 21,
        },
        "6.5": {
          2: 0,
          3: 0,
          5: 1,
          8: 1,
          13: 2,
          20: 3,
          32: 5,
          50: 7,
          80: 10,
          125: 14,
          200: 21,
          315: 21,
          500: 21,
          800: 21,
          1250: 21,
          2000: 21,
        },
      };

      if (aql == "Not Allowed") {
        return 0;
      } else {
        let num = Number(suggestedQuantity);
        // 根据aql值和抽检数量在data对象获取对应值
        return data[aql][num] || 0;
      }
    },
    async getDefectList() {
      this.tableData = []
      this.defectList = cloneDeep(this.articleInfo?.inspectionList?.defectList || [])
      const compare = function (objA, objB) {
        return objA.defectTypeCode.localeCompare(objB.defectTypeCode)
      }
      this.defectList = this.defectList.sort(compare)
      this.defectList.forEach(item => {
        // this.tableData.push([`${item.defectTypeName}-${item.defectName}`, item.name, item.cri, item.maj, item.min])
        this.tableData.push([`${item.defectTypeName}-${item.defectName}`, item.cri, item.maj, item.min])
      })
      const aqlCri = this.tableData.reduce((prev, cur) => {
        return prev + cur[1]
      }, 0)
      const aqlMaj = this.tableData.reduce((prev, cur) => {
        return prev + cur[2]
      }, 0)
      const aqlMin = this.tableData.reduce((prev, cur) => {
        return prev + cur[3]
      }, 0)
      this.tableData.push(['总计', aqlCri, aqlMaj, aqlMin])
      const cri = this.getReferenceData(this.articleInfo.aqlCri, this.articleInfo.suggestedQuantity)
      const maj = this.getReferenceData(this.articleInfo.aqlMaj, this.articleInfo.suggestedQuantity)
      const min = this.getReferenceData(this.articleInfo.aqlMin, this.articleInfo.suggestedQuantity)
      this.tableData.push(['参考值', cri, maj, min])
      // this.tableData.push(['参考值', this.articleInfo.aqlCri, this.articleInfo.aqlMaj, this.articleInfo.aqlMin])
    }
  }
}
</script>

<style lang="less" scoped>

</style>