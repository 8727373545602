<template>
  <card title="备注明细">
    <div class="remark-list">
      <no-data v-if="!loading && remarkList && remarkList.length === 0" />
      <div
        v-for="(item, index) in remarkList"
        :key="index"
        class="list-item space-between vertical-center"
        @click="handleItemClick(item, index)"
      >
        <div class="info">
          <div class="name" style="font-size: 14px; color: #4B4E51; font-weight: bold">
            {{ index + 1 }}. {{ item.description }}
          </div>
          <div style="padding-left: 16px; margin-top: 16px; font-size: 12px; color: #797C80;">
            <span v-if="item.inspectItemName">
              关联检查点：{{ item.checkPointName }}
            </span>
            <span v-else>
              关联检查点：无
            </span>
          </div>
          <div v-if="item.pictures" class="picture-wrap">
            <image-uploader
              v-model="item.picturesList"
              :deletable="false"
              :show-upload="false"
            ></image-uploader>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import CustomPopup from "@/components/Common/CustomPopup.vue";
import RemarkDetailPopup from "@/components/V2/Remark/RemarkDetailPopup.vue";
import NewRemarkPopup from "@/components/V2/Remark/NewRemarkPopup.vue";
import NoData from "@/components/Common/NoData";
import {getAction, deleteAction} from "@/api/manage";
import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import Card from "@/components/Common/Card.vue";
import {imgStrToList} from "@/utils/util";
import ImageUploader from "@/components/Common/ImageUploader.vue";

export default {
  name: "RemarkListCard",
  components: {ImageUploader, Card, NoData, NewRemarkPopup, RemarkDetailPopup, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    defaultCheckItemObj: {
      type: Object,
      default() {
        return {}
      },
    },
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      remarkList: [],
      isShowDetail: false,
      isShowForm: false,
      selectIndex: null,
      loading: false,
      order: {},
    };
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  watch: {
    taskInfo() {
      this.getRemarkList()
    }
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    handleItemClick(item, index) {
      this.selectIndex = index;
      this.$refs.detail.disabled = this.readonly;
      this.$refs.detail.form = item;
    },
    handleAdd() {
      this.selectIndex = null;
      this.$refs.form.init();
    },
    handleEdit() {
      this.$refs.form.init(this.remarkList[this.selectIndex]);
    },
    async handleFormOk() {
      await this.getTaskInfo()
      this.getRemarkList();
    },
    getSubmitData(){
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.inspectionList.remarkList = this.remarkList
      return taskInfo
    },
    handleDel() {
      this.Dialog.confirm({
        title: "",
        message: "确认删除该备注？",
      }).then(async () => {
        this.remarkList.splice(this.selectIndex, 1)
        const taskInfo = this.getSubmitData()
        const res = await this.saveTaskInfo(taskInfo)
        if (res.success) {
          await this.getTaskInfo()
        }
        this.getRemarkList()
      }).catch((e) => {
        this.getRemarkList()
      });
    },
    async getRemarkList() {
      const orderList = this.taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId) || {}
      const articleNoList = order?.articleNoList || []
      const article = articleNoList.find(item => item.id === this.articleId)
      const remarkList = cloneDeep(article?.inspectionList?.remarkList || [])
      if(article.remarks) {
        remarkList.push({
          checkPointName: null,
          description: article.remarks,
          id: 'remarks',
          pictures: '',
        })
      }
      this.remarkList = remarkList.map(item => {
        item.picturesList = imgStrToList(item.pictures)
        return item
      })
    },
  },
};
</script>

<style scoped lang="less">
.remark-list {
  .list-item {
    padding: 6px 8px;
    border-bottom: 1px solid #dfe4eb;

    &:last-child {
      border-bottom: none;
    }

    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }

    .picture-wrap {
      margin-top: 16px;
      padding-left: 16px;
      img {
        width: 80px;
        height: 60px;
        object-fit: cover;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }

    .info {
      font-size: 14px;

      .name {
        color: rgba(#000, 0.9);
      }

      span {
        color: rgba(#000, 0.4);
        margin-right: 8px;
      }
    }
  }

  .new-btn {
    margin-top: 25px;

    &.disabled {
      display: none;
    }
  }
}
</style>
