<template>
  <card :title="checkPoint ? checkPoint.pointsName : ''">
    <check-form ref="checkForm" visible readonly/>
    <check-defect-remark-detail
      :article-check-point-id="checkPoint.id"
      :remark-list="remarkList"
      :defect-list="defectList"
    ></check-defect-remark-detail>
    <div class="form-item">
      <div class="label">检查结果：</div>
      <el-radio-group
        v-model="checkPoint.conclusion"
        disabled
      >
        <el-radio
          v-for="key in Object.keys(CHECK_JUDGE_DICT)"
          :key="key"
          :label="key"
        >
          {{ CHECK_JUDGE_DICT[key] }}
        </el-radio>
      </el-radio-group>
    </div>
  </card>
</template>

<script>
import Card from "@/components/Common/Card.vue";
import CheckForm from "@/components/V2/Check/CheckForm.vue";
import {CHECK_JUDGE_DICT} from "@/constants";
import {cloneDeep} from "lodash";
import {imgStrToList} from "@/utils/util";
import {mapGetters} from "vuex";
import imageUploader from "@/components/Common/ImageUploader.vue";
import CheckDefectRemarkDetail from "@/components/Common/CheckDefectRemarkDetail.vue";

export default {
  name: "CheckItemCard2",
  components: {CheckDefectRemarkDetail, imageUploader, CheckForm, Card},
  props: {
    checkPoint: {
      type: Object,
      default: () => {
      }
    },
    defectList: {
      type: Array,
      default() {
        return []
      }
    },
    remarkList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      CHECK_JUDGE_DICT,
    }
  },
  mounted() {
    this.$refs.checkForm.setForm(this.checkPoint)
  },
  methods: {}
}
</script>

<style lang="less" scoped>

.form-item {
  display: flex;
  align-items: flex-start;
  .label {
    width: 80px;
    margin: 0 4px;
    font-size: 12px;
    color: #AAB3BA;
    flex-shrink: 0;
  }

  ::v-deep.el-radio-group {
    display: flex;

    .el-radio {
      margin-right: 5px;

      .el-radio__label {
        padding-left: 2px;
      }
    }
  }
}

</style>